import { Checkbox, Col, Form, List, Row, Select, Typography } from 'antd';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import useMsg from 'hooks/translations/useMsg';
import { useClaimContext } from 'providers/ClaimDeclaration';
import { useUser } from 'providers/user/User';
import React, { Fragment, useMemo } from 'react';
import { useAppState } from 'providers/AppState';

const { Title, Paragraph } = Typography;
// THIS IS HC PLEASE FIX WITH A CALL IN BE
const productsOnlyDamage = [
  'BM_JP_NOMAD_DAMAGE_10000_STANDARD',
  'BM_JP_NOMAD_DAMAGE_30000_STANDARD',
  'BM_JP_NOMAD_DAMAGE_50000_STANDARD',
  'BM_JP_NOMAD_DAMAGE_100000_STANDARD',
];
export default function Consent() {
  const { partner } = useAppState();
  const { user } = useUser();
  const { setClaimType, claimType, productId } = useClaimContext();
  const detailsMsg = useFormatMsg('claims.declaration.details');
  // THIS IS HC PLEASE FIX WITH A CALL IN BE
  const claimTypes = useMemo(
    () => ({
      BM: ['DAMAGE', 'THEFT', 'LOSS'],
      BMUK: ['DAMAGE', 'THEFT', 'LOSS'],
      BMJP: productsOnlyDamage.includes(productId)
        ? ['DAMAGE']
        : ['DAMAGE', 'DATA_RECOVERY'],
      DI: ['DAMAGE', 'THEFT', 'LOSS'],
      RF: ['DAMAGE', 'THEFT'],
      FN: ['DAMAGE', 'THEFT', 'LOSS', 'WARRANTY_EXTENSION'],
      DICH: ['DAMAGE', 'THEFT', 'WARRANTY_EXTENSION'],
      SA: ['DAMAGE', 'THEFT', 'LOSS', 'WARRANTY_EXTENSION'],
      YA: ['DAMAGE', 'THEFT', 'LOSS', 'WARRANTY_EXTENSION'],
      MO: ['DAMAGE', 'THEFT', 'LOSS', 'WARRANTY_EXTENSION'],
      MI: ['DAMAGE', 'THEFT', 'WARRANTY_EXTENSION'],
      OP: ['DAMAGE'],
    }),
    [productId],
  );
  const items = useMsg(
    `claims.declaration.consent.agreement.items.${claimType}`,
  );
  const msg = useFormatMsg('claims.declaration.consent');
  return (
    <Fragment>
      <Title level={5}>{msg('title.claimType')}</Title>
      <Row justify="space-between" gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            name="claimType"
            label={msg('claimType.label')}
            rules={[{ required: true, message: msg('required') }]}
            labelCol={{ span: 24 }}
          >
            <Select onChange={setClaimType}>
              {claimTypes[partner].map(type => (
                <Select.Option key={type} value={type}>
                  {msg(`claimType.${type}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Title style={{ marginTop: '2rem' }} level={5}>
        {msg('title.declarationConsent')}
      </Title>
      <Paragraph>
        {msg('agreement.text', { firstName: user.firstName })}
      </Paragraph>
      <ol style={{ marginLeft: '2rem' }}>
        {items.map((item, index) => (
          <li key={index}>
            <List.Item.Meta
              title={item.title}
              description={
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                ></p>
              }
            />
          </li>
        ))}
      </ol>

      <Form.Item
        style={{ marginBottom: '0px' }}
        name="agreement"
        labelAlign="right"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(msg('agreement.validationMsg')),
          },
        ]}
      >
        <Checkbox>{msg('agreement.checkbox')}</Checkbox>
      </Form.Item>
      <Form.Item
        name="agreementBolttech"
        labelAlign="right"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(msg('agreement.validationMsg')),
          },
        ]}
      >
        <Checkbox>{msg('agreement.checkboxBolttech')}</Checkbox>
      </Form.Item>
    </Fragment>
  );
}
