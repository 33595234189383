import { useState } from 'react';
import { useAppState } from 'services/providers/AppState';

function useAuthForm(handleSubmit, { email: mail } = {}) {
  const [email, setEmail] = useState(mail || '');
  const { partner } = useAppState();
  const [password, setPassword] = useState();
  const [repeatedPassword, setRepeatedPassword] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [birthdate, setBirthdate] = useState();

  const onFormSubmit = () => {
    const partnerId = selectPartnerId(partner);
    const payload = {
      repeatedPassword,
      password,
      email,
      partnerId,
      phoneNumber,
      birthdate,
    };
    handleSubmit(payload);
  };
  return {
    setEmail,
    setPassword,
    setRepeatedPassword,
    onFormSubmit,
    setPhoneNumber,
    setBirthdate,
  };
}

const selectPartnerId = partner => {
  switch (partner) {
    case 'BMJP':
      return 'BACKMARKET_JP';
    case 'BMUK':
      return 'BACKMARKET_UK';
    case 'BM':
      return 'BACKMARKET';
    case 'DI':
      return 'GALAXUS';
    case 'RF':
      return 'REFURBED';
    case 'YA':
      return 'YALLO';
    case 'DICH':
      return 'DIGITEC';
    case 'SA':
      return 'SALT';
    case 'FN':
      return 'FNAC';
    case 'MI':
      return 'MICROSPOT_CH';
    case 'MO':
      return 'MONACO';
    case 'RFWE':
      return 'REFURBED_EW';
    case 'OP':
      return 'OUTPOST24';
    default:
      return 'BACKMARKET';
  }
};

export default useAuthForm;
