import React from 'react';
import { Link } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip, Typography } from 'antd';
import StatusTag from 'components/shared/StatusTag';
import { formateDateDDMMYYYY } from 'helpers/dates/formatDate';
import policyCodeToColor from 'helpers/statuses/policy/policyCodeToColor';
import policyCodeToSatuses from 'helpers/statuses/policy/policyCodeToSatuses';
import useFormatMsg from 'hooks/translations/useFormatMsg';
import { capitalize as cap } from 'helpers/strings';
import { useAppState } from 'services/providers/AppState';

const { Paragraph, Title, Text } = Typography;

export default function PolicyInformation({ data }) {
  const msg = useFormatMsg('policies.policy_card');

  const policyStatus = policyCodeToSatuses(data.status);
  const { partner } = useAppState();

  const titleFromPartner = () => {
    switch (partner) {
      case 'RF':
        return `${data.policyName} - ${data.externalNumber}`;
      default:
        return data.policyName;
    }
  };

  return (
    <>
      <Row justify="space-between">
        <Title
          level={4}
          style={{ marginBottom: '8px', color: 'var(--primary)' }}
        >
          {cap(msg('title'))} - {titleFromPartner()}
        </Title>
        <StatusTag
          data-cy="claim_status"
          style={{ height: 'fit-content' }}
          color={policyCodeToColor(data.status)}
        >
          {msg(`information.status.${policyStatus}`)}
        </StatusTag>
      </Row>
      <Row justify="space-between">
        <Col>
          <Title level={5}>{cap(msg('device.title'))}</Title>
          <Paragraph data-cy="description" style={{ marginBottom: '4px' }}>
            <Text strong>{msg('make_and_model')}</Text>
            {data.insuredItems?.[0]?.description}
          </Paragraph>
          <Paragraph data-cy="serialNumber">
            <Tooltip title={msg('imei_tooltip')} placement="right">
              <Text strong>{msg('serialNumber')}</Text>
              {data.insuredItems?.[0]?.serialNumber || (
                <>
                  <InfoCircleOutlined
                    style={{ color: 'red', marginRight: '4px' }}
                  />
                  <Link
                    data-cy="register_device"
                    to={`/dashboard/register-device/${data.policyName}`}
                    style={{ color: 'red' }}
                  >
                    {msg('registerdevice')}
                  </Link>
                </>
              )}
              <InfoCircleOutlined style={{ marginLeft: '8px' }} />
            </Tooltip>
          </Paragraph>
        </Col>
        <Paragraph>
          {`${formateDateDDMMYYYY(data.startDate)} - ${formateDateDDMMYYYY(
            data.endDate,
          )}`}
        </Paragraph>
      </Row>
    </>
  );
}
