import { compareStatus } from 'data/insuredItems';
import * as Sentry from '@sentry/browser';

export const policyListMapper = results => {
  const { links, meta, data, included } = results.data;
  const products = included.filter(item => item.type === 'Product');
  // const insuredItems = included.filter(item => item.type === 'InsuredItem');
  const includedThings = retriveIncluded(included);

  return {
    links,
    meta,
    data: data.map(({ attributes, id, relationships }) => {
      const productCoverage = products.find(product =>
        product.id.includes(relationships?.product?.data?.id),
      );
      return {
        id,
        ...attributes,
        policyName: attributes.label,
        productId: productCoverage?.id, // includedThings[relationships?.product?.data.id].id,
        insuredItems: relationships.insuredItems?.data
          .map(({ id }) => ({
            id,
            ...includedThings[id].attributes,
            item: includedThings?.items?.[
              includedThings?.[id]?.attributes?.item
            ],
          }))
          .sort(compareStatus),
      };
    }),
  };
};

export const claimListMapper = results => {
  const { links, meta, data, included } = results.data;
  const includedThings = retriveIncluded(included);
  return {
    links,
    meta,
    data: data.map(({ id, attributes, relationships }) => ({
      id,
      ...relationships,
      ...attributes,
      files: relationships?.files?.data?.map(f => includedThings[f.id]),
      claimContext: relationships?.claimContext?.data?.map(
        cc => includedThings[cc.id]?.attributes,
      ),
      damages: relationships?.damages?.data?.map(
        d => includedThings[d.id]?.attributes?.category,
      ),
      insuredItem: {
        ...includedThings[relationships?.insuredItem?.data?.id]?.attributes,
        item: includedThings?.items?.[
          includedThings[relationships?.insuredItem?.data?.id]?.attributes?.item
        ],
      },
    })),
  };
};

export const customerOverviewMapper = results => {
  const { data, included } = results.data;
  const { attributes } = data;
  return {
    customerId: data.id,
    firstName: attributes.firstName,
    lastName: attributes.lastName,
    phone: attributes.phone,
    email: attributes.email,
    partner: attributes.partner,
    birthDate: attributes.birthDate,
    addressId: included?.[0].id,
    address: included?.[0].attributes.streetAddress,
    postalCode: included?.[0].attributes.postalCode,
    addressLocality: included?.[0].attributes.addressLocality,
    addressCountry: included?.[0].attributes.addressCountry,
    birthDate: attributes.birthDate,
    language: attributes.language,
    salutation: attributes.salutation,
  };
};

export const getInsuredItemsMapper = results => {
  try {
    const { included, data } = results.data;
    const insured = included.filter(item => item.type === 'InsuredItem');
    const { items } = included.find(item => !!item.items);
    const insuredItems = insured
      .map(device => {
        const item = items.find(item => device.attributes.item === item.id);
        return {
          id: device.id,
          ...device.attributes,
          itemId: device.attributes.item,
          ...item.attributes,
        };
      })
      .sort(compareStatus);
    const returnedProduct = data.relationships?.product?.data?.id;
    return { insuredItems, returnedProduct };
  } catch (err) {
    Sentry.captureMessage(JSON.stringify(results));
  }
};

export const claimPolicyListMapper = results => {
  const { data } = results.data;
  const claimByPolicy = data.reduce(
    (p, n) => ({
      ...p,
      [n.relationships.policy.data.id]: [
        ...(p[n.relationships.policy.data.id] || []),
        { id: n.id, ...n.attributes },
      ],
    }),
    {},
  );

  return claimByPolicy;
};

export const editCustomerMapper = payload => ({
  phone: payload.phone,
  birthDate: payload.birthDate,
  address: [
    {
      id: payload.addressId,
      streetAddress: payload.address,
      postalCode: payload.postalCode,
      addressLocality: payload.addressLocality,
      addressCountry: payload.addressCountry,
    },
  ],
});

export const policyCountryMapper = data => {
  return { country: data.data.data.attributes.country };
};

const retriveIncluded = included =>
  included?.reduce(
    (p, n) => ({
      ...p,
      [n.id ?? 'items']: !n.items
        ? n
        : n.items.reduce(
            (p2, n2) => ({
              ...p2,
              [n2.id]: n2.attributes,
            }),
            {},
          ),
    }),
    {},
  );

export const workOrderMapper = results => {
  const { data } = results.data;
  const dataFiltered = data.filter(wo => wo.attributes.status !== 50902);
  return dataFiltered.map(wo => ({
    id: wo.id,
    ...wo.attributes,
  }));
};

export const voucherMapper = results => {
  const { data } = results.data;
  return data.map(v => ({
    id: v.id,
    ...v.attributes,
  }));
};
