import Spinner from 'components/shared/Spinner';
import useInitialQuery from 'hooks/fetch/useInitialQuery';
import React from 'react';
import { useParams } from 'react-router-dom';
import FullDeviceRegistration from './FullDeviceRegistration';
import IMEIRegistration from './IMEIRegistration';

export default function RegisterDevice({}) {
  const { policyId } = useParams();

  const { data, isLoading } = useInitialQuery('getInsuredItems', {
    payload: policyId,
  });

  if (isLoading) return <Spinner />;

  if (!data?.insuredItems || Object.keys(data?.insuredItems).length === 0) {
    return <FullDeviceRegistration />;
  }

  return <IMEIRegistration data={data?.insuredItems} />;
}
