export const partnersAvailable = {
  BM: 'BACKMARKET',
  BMUK: 'BACKMARKET_UK',
  BMJP: 'BACKMARKET_JP',
  DI: 'GALAXUS',
  RF: 'REFURBED',
  RFWE: 'REFURBED_EW',
  SA: 'SALT',
  FN: 'FNAC',
  MI: 'MICROSPOT_CH',
  DICH: 'DIGITEC',
  YA: 'YALLO',
  MO: 'MONACO',
  OP: 'OUTPOST24',
};
