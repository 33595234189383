const montserratFont = 'Montserrat, sans-serif';
const proximaNovaFont = 'Proxima Nova, sans-serif';

const partnersThemes = {
  BM: {
    token: {
      colorPrimary: '#3fc69c',
      fontFamily: montserratFont,
    },
  },
  RF: {
    token: {
      colorPrimary: '#387de0',
      fontFamily: proximaNovaFont,
    },
  },
  RFWE: {
    token: {
      colorPrimary: '#387de0',
      fontFamily: proximaNovaFont,
    },
  },
  BMUK: {
    token: {
      colorPrimary: '#3fc69c',
      fontFamily: montserratFont,
    },
  },
  DI: {
    token: {
      colorPrimary: '#262626',
      fontFamily: montserratFont,
    },
  },
  DICH: {
    token: {
      colorPrimary: '#a2dc87',
      fontFamily: montserratFont,
    },
  },
  FN: {
    token: {
      colorPrimary: '#00bac7',
      fontFamily: montserratFont,
    },
  },
  MI: {
    token: {
      colorPrimary: '#00ae6d',
      fontFamily: montserratFont,
    },
  },
  MO: {
    token: {
      colorPrimary: '#ff0909',
      fontFamily: montserratFont,
    },
  },
  OP: {
    token: {
      colorPrimary: '#154865',
      fontFamily: montserratFont,
    },
  },
  SA: {
    token: {
      colorPrimary: '#00bac7',
      fontFamily: montserratFont,
    },
  },
  YA: {
    token: {
      colorPrimary: '#00bac7',
      fontFamily: montserratFont,
    },
  },
};

export default partnersThemes;
